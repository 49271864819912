.dashboard-component-chart-holder:has(.player-header) {
  padding-left: 24px !important;
  display: flex;
  align-items: center;
}

.handlebars:has(.player-header) {
  & > div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0;
  }
}

.player-header {
  --player-avatar-border-color: #205cfa;

  text-align: left;

  .player-info {
    display: flex;
    align-items: center;
  }

  .player-photo {
    height: 100%;
    width: auto;
    max-height: 88px;
    margin-right: 24px;
    border: 4px solid var(--player-avatar-border-color);
    border-radius: 50%;
  }

  .player-details {
    display: flex;
    flex-direction: column;
  }

  .player-name {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 5px;
  }

  .team-info {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .team-info p {
    font-size: 14px;
    margin: 0;
  }
}
