.beeswarmTooltipContainer {
  display: none;
  padding: 8px !important;
  border-radius: 8px !important;
  color: var(--kognia-white-color) !important;
  background: var(--kognia-typography-secondary-color) !important;
  box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.16) !important;
  font-family: 'Rubik';

  &:hover {
    display: block;
  }

  .beeswarmTooltipWrapper {
    display: flex;
    align-items: center;
    column-gap: 8px;
  }

  .beeswarmTooltipImgWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px;
  }

  .beeswarmTooltipImg {
    display: block;
    width: auto;
    height: 60px;
  }

  .beeswarmTooltipTitle {
    font-size: var(--kognia-typography-font-size-sm);
    font-weight: var(--kognia-typography-font-weight-lg);
    line-height: var(--kognia-typography-line-height-sm);
  }

  .beeswarmTooltipContentContainer {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    padding-right: 8px;
  }

  .beeswarmTooltipExtraDataWrapper {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
  }

  .beeswarmTooltipExtraDataRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 31px;
  }

  .beeswarmTooltipExtraDataRowTitle {
    font-size: var(--kognia-typography-font-size-sm);
    line-height: var(--kognia-typography-line-height-sm);
  }

  .beeswarmTooltipExtraDataRowValue {
    font-size: var(--kognia-typography-font-size-sm);
    font-weight: var(--kognia-typography-font-weight-lg);
    line-height: var(--kognia-typography-line-height-sm);
  }
}
