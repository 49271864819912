.table-overview {
  --thead-color: #6f738c;
  --thead-bg-color: #ededf2;
  --border-color: #ededf2;
  --play-button-color: #7a7e98;
  --play-button-hover-color: #bbb3ff;

  width: calc(100% + 16px);
  margin-left: -16px;
  margin-top: -18px;
  color: var(--kognia-typography-color);
  background: var(--kognia-white-color);
  border-collapse: collapse;
  text-align: left;
  font-size: var(--kognia-typography-font-size-sm);
  font-style: normal;
  font-weight: 400;
  line-height: 16px;

  tbody tr {
    border-bottom: 1px solid var(--border-color);
  }

  th {
    padding: 6px 24px;
    height: 46px;
    min-height: 46px;
    line-height: 24px;
    min-width: 190px;
    position: sticky;
    top: -18px;
    left: -16px;
    color: var(--thead-color);
    background: var(--thead-bg-color);
    font-weight: 500;
    text-align: center;
    z-index: 1;

    &.th-narrow {
      min-width: 128px;
    }

    &.th-regular {
      min-width: 198px;
    }

    &.th-wide {
      min-width: 288px;
    }

    &:first-child {
      z-index: 2;
      min-width: 288px;
      padding-right: 48px;
      border-top-left-radius: 8px;
      text-align: start;
    }

    &:last-child {
      border-top-right-radius: 8px;
    }
  }

  td {
    padding: 6px 24px;
    text-align: center;
    left: 0;
    line-height: var(--kognia-typography-line-height-xsm);

    &:first-child {
      position: sticky;
      left: -16px;
      z-index: 1;
      background: var(--kognia-white-color);
      font-weight: var(--kognia-typography-font-weight-md);
      text-align: start;
    }
  }

  .link-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }

  .link-container a {
    outline: none;
  }

  .play-button-container {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .play-button {
    width: 11px;
    height: 14px;
    position: relative;
  }

  .play-button::before {
    content: '';
    display: block;
    position: absolute;
  }

  .play-button::before {
    left: 0;
    top: 0;
    border-style: solid;
    border-width: 7px 0 7px 12px;
    border-color: transparent transparent transparent var(--play-button-color);
    transition: border-color 0.3s ease;
  }

  .play-button:hover::before {
    border-color: transparent transparent transparent
      var(--play-button-hover-color);
  }

  .game-summary {
    display: flex;
    gap: 16px;
    white-space: nowrap;
    align-items: center;
  }

  .team-logo {
    display: block;
    width: auto;
    height: 24px;
  }

  .match-score,
  .team-name {
    font-weight: var(--kognia-typography-font-weight-md);
  }

  .stage-name,
  .match-date {
    font-weight: normal;
  }

  .game-details {
    display: flex;
    gap: 8px;
    width: fit-content;
    padding: 0 16px 0 0;
    align-items: center;
  }

  .table-icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
  }

  .game-extra-details {
    display: flex;
    white-space: nowrap;
    flex-direction: column;
    gap: 2px;
    width: fit-content;
    padding: 0 16px;
  }

  @media (max-width: 1439px) {
    font-size: var(--kognia-typography-font-size-xsm);

    th {
      padding: 6px 12px;

      &.th-narrow {
        min-width: 100px;
      }

      &.th-regular {
        min-width: 160px;
      }

      &.th-wide {
        min-width: 220px;
      }

      &:first-child {
        min-width: auto;
        padding-right: 0;
        text-align: start;
      }
    }

    td {
      padding: 6px 12px;
    }

    .game-summary {
      gap: 8px;
    }

    .game-details {
      gap: 4px;
      padding: 0 8px 0 0;
    }

    .game-extra-details {
      padding: 0 8px;
    }

    .table-icon-wrapper {
      width: 20px;
      height: 20px;
    }

    .team-logo {
      height: 20px;
    }
  }
}

.table-overview.auto-cell-size {
  th {
    min-width: fit-content;
    width: fit-content;

    &.th-narrow {
      min-width: fit-content;
    }

    &.th-regular {
      min-width: fit-content;
    }

    &.th-wide {
      min-width: fit-content;
    }
  }

  @media (max-width: 1439px) {
    th {
      &.th-narrow {
        min-width: fit-content;
      }

      &.th-regular {
        min-width: fit-content;
      }

      &.th-wide {
        min-width: fit-content;
      }
    }
  }
}
