/* Hide the title for all Handlebars charts */
[data-test-viz-type='handlebars'] > div:first-child {
  display: none;
}

/* Hide the tab share button */
[class='anchor-link-container'] {
  display: none;
}

/* Disable header filters for all charts */
div > div.header-controls > div[class*='filter'] {
  display: none;
}

/* [CHART-2] Disable all chart options except "Download" */
#slice_2-menu > li:not(:has(> div[title='Download'])) {
  display: none;
}

[data-test='refresh-chart-menu-item'] {
  display: none !important;
}
