.handlebars:has(.header-stats) {
  & > div {
    padding: 0;
  }
}

.header-stats {
  --separator-color: #e1e4e8;

  display: flex;
  flex-direction: column;
  gap: 10px;

  .stat-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .stat-separator {
    width: 1px;
    height: 40px;
    background: var(--separator-color);
  }

  .stat {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .stat-label {
    font-size: 12px;
    font-weight: 300;
  }

  .stat-value {
    font-size: 16px;
    font-weight: 500;
    line-height: 30px;
  }
}
