:root {
  --kognia-typography-font-size-sm: 14px;
  --kognia-typography-font-size-xsm: 12px;

  --kognia-typography-font-weight-md: 500;
  --kognia-typography-font-weight-lg: 600;

  --kognia-typography-line-height-sm: 171.429%;
  --kognia-typography-line-height-xsm: 133.333%;
}
